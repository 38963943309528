$(document).foundation();
var $form = $('#aria_contact_form');
$form
.bind("submit", function(e) {
  e.preventDefault();
})
.bind("formvalid.zf.abide", function(e,$form) {
        var name = $("input#name").val();
        var email = $("input#email").val();
        var message = $("textarea#message").val();
        var dataString = 'name=' + name + '&email=' + email + '&message=' + message;
        $.ajax({
            type: "POST",
            url: "assets/php/mail.php",
            data: dataString,
            success: function success() {
                $('.contactform').html("<div id='thanks'></div>");
                $('#thanks').html("<h3>Tak!</h3>").append("<p>  Vi vil kontakte dig snarest muligt. </p>").hide().fadeIn(1500);
            }
        }); //ajax call ends

        return false;
});

    
$("button[type!='submit']").click(function() {
    $('#'+this.id+'_ul').foundation('toggle', $('#'+this.id+'_content'));
});

$("a").click(function() {
    var val = this.id.slice(0,-1);
    $('#'+val+'_ul').foundation('toggle', $('#'+val+'_content'));
});


